<template>
<div>
     <el-row type="flex" class="electric">
            <el-col  >
                  <div></div>
                  <div>
                    <span>{{classData.totalName}}</span>
                    <div>
<!--                      <span>{{classData.total}}</span>-->
                      <span>{{toDeFilter(classData.total)}}</span>
                      <span>{{classData.unit}}</span>
                    </div>
                  </div>
            </el-col>
            <el-col >
                 <div></div>
                  <div>
                    <span>{{classData.daysName}}</span>
                    <div>
<!--                      <span>{{classData.currentday}}</span>-->
                      <span>{{toDeFilter(classData.currentday)}}</span>
                      <span>{{classData.unit}}</span>
                    </div>
                  </div>
            </el-col>
            <el-col >
                  <div></div>
                  <div>
                    <span>{{classData.monthsName}}</span>
                    <div>
<!--                      <span>{{classData.currentMonth}}</span>-->
                      <span>{{toDeFilter(classData.currentMonth)}}</span>
                      <span>{{classData.unit}}</span>
                    </div>
                  </div>
            </el-col>
          </el-row>
           <el-row>
           <div class="statistics">

           </div>
           <div class="echarts-statistics">
             <div v-show="isShow">
               <div id="reuse-pie"></div>
             </div>
             <div>
                <div id="consumed" ></div>
             </div>
           </div>
          </el-row>
</div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name:'revebueComponent',
    props:{
       classData:{
           type:Object,
           required:true,
           value:()=>{}
       },
       newObj:{
          type:Object,
           required:true,
           value:()=>{}
       },
       timeState:{
           type:Array,
           required:false,
           value:()=>[]
       },
       newPie:{
         type:Object,
         required:true,
         value:()=>{}
       },
       isShow:{
         type:Boolean,
         required:false,
         value:()=>true
       }
    },
  data() {
    return {
      myChart:null,
      myChart1:null

    }
  },
  watch:{
      timeState:{
         handler:function(newValue,oldValue){
           if(this.myChart){
                if(newValue){
                var option = this.myChart.getOption()
                    option.xAxis[0].data = newValue
                    this.myChart.setOption(option)
                }else{
                    var option = this.myChart.getOption()
                        option.xAxis[0].data = oldValue
                        this.myChart.setOption(option)
                }
           }else{
                this.renderInit()
           }
      },
      deep:true
     },
     newObj:{
         handler:function(newValue,oldValue){
        if(this.myChart){
             if(newValue){
           var option = this.myChart.getOption()
            option.series = newValue.list
            option.title[0].text = newValue.name
            option.legend[0].data = newValue.item
            this.myChart.setOption(option)
            }else{
                var option = this.myChart.getOption()
                option.series = oldValue.list
                 option.title[0].text = oldValue.name
                option.legend[0].data = oldValue.item
                    this.myChart.setOption(option)
            }
        }else{
             this.renderInit()
        }
      },
      deep:true
     },
     newPie:{
        handler:function(newValue,oldValue){
        if(this.myChart1){
             if(newValue){
              var option = this.myChart1.getOption()
              option.series[0].data = newValue.list
               option.title[0].text = newValue.title
             this.myChart1.setOption(option)
            }else{
              var option = this.myChart1.getOption()
              option.series[0].data = oldValue.list
              option.title[0].text = oldValue.title
              this.myChart1.setOption(option)
            }
        }else{
             this.renderEchats()
        }
      },
        deep:true
    }
  },
  mounted() {
    this.renderInit()
    this.renderEchats()
  },
  methods: {
    toDeFilter(val){
      if(val.toString().indexOf('.') !== -1){
        return val
      }else{
        return val +'.00'
      }
    },
      renderInit(){
            this.myChart = echarts.getInstanceByDom(document.getElementById('consumed'))
            if(!this.myChart){
                this.myChart = echarts.init(document.getElementById('consumed'));
            }

         var  option = {
            title:{
                text:this.newObj.name,
                left:'0%',
                top:'1%',
                textStyle:{
                  color:'#5d5d5d'
                }
            },
               tooltip: {
                  trigger: 'axis',
                  axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                      type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                  }
              },
            legend:{
               top:'1%',
               right:'20%',
              data:this.newObj.item
            },
            grid:{
                left:'6%',
                top:'15%',
                right:'1%',
                bottom:'10%'
            },
            xAxis: {
                type: 'category',
                data:this.timeState,
                axisTick:{
                    show:false
                },
                  axisLine:{
                      lineStyle:{
                          color:'#e4e4e4'
                      }
                  },
                  axisLabel:{
                      color:'#333'
                  }
            },
            yAxis: {
                type: 'value',
                show:true,
                axisTick:{
                    show:false
                }
            },
            series:this.newObj.list
        };

        this.myChart.setOption(option)
        let chart = this.myChart
         setTimeout( ()=>{
              window.addEventListener("resize", () => { chart.resize(); })
            },200)


     },
     renderEchats(){
            this.myChart1 = echarts.getInstanceByDom(document.getElementById('reuse-pie'))
            if(!this.myChart1){
                this.myChart1 = echarts.init(document.getElementById('reuse-pie'));
            }
           var  option = {
                 title:{
                          text:this.newPie.title,
                          top:'0%',
                          left:'2%',
                          textStyle:{
                              color:'#5d5d5d'
                          }
                        },
                tooltip: {
                    trigger: 'item'
                },
                 legend: {
                            top: '5%',
                            right: '5%',
                            top:'bottom',
                            orient:'vertical',
                            itemGap:10
                    },
                series: [
                    {
                        name: '换电结果统计',
                        type: 'pie',
                        radius: ['35%', '55%'],
                        center:['35%','50%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '18',
                                fontWeight: 'bold',
                                formatter:'{b}\n{c}%'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: this.newPie.list
                    }
                ]
            };

        this.myChart1.setOption(option)
        let chart = this.myChart1
         setTimeout( ()=>{
              window.addEventListener("resize", () => { chart.resize(); })
            },200)
       }


  },
};
</script>

<style lang='less' scoped>

.electric{
   height: 160px;
   .el-col{
     width: 255px;
     height: 80px;
     border-radius: 10px;
     background-color: #2a82e4;
     margin: 20px;
     display: flex;
     color: #fff;
     font-size: 18px;
     &:nth-child(2){
       background-color: #ee9e4d;
       margin:20px  40px;

     }
     &:nth-child(3){
       background-color: #00baad;
     }
     >div{
        &:nth-child(1){
          width: 60px;
          height: 100%;
          background: url('../../assets/image/battery.png') no-repeat;
          background-size: 80%;
           background-position: 50% 50%;

        }
        &:nth-child(2){
          width: calc(~'100% - 60px');
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
        }

     }
   }
 }
  .statistics{
   border-bottom: 1px solid #f3f3f3;
   margin-bottom: 20px;
 }
 .echarts-statistics{
   display: flex;
   div{
     &:nth-child(1){
       flex: 3;
     }
     &:nth-child(2){
       flex: 7;
     }
   }
 }
 #consumed{
      width: 100%;
    height: 400px;
   }
    #reuse-pie{
     width: 100%;
     height: 400px;
 }
</style>
