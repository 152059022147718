<template>
    <div ref="main" id="myChinaMap"></div>
</template>
<script>
import * as echarts from 'echarts';
import chinaJson from '@/components/map/chinaMap.json';
import {staDistribution} from "@/utils/index";
require('@/components/map/chalk')
export default {
    name: "china-map",
    data() {
        return {
            chart: '',
            status1:[],
            status2:[],
            status3:[],
            status4:[],

            mapData:[],
            mapIntervalTimer:null,
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initChart()
        })

    },
    created() {
        this.getMapData();
        //this.getStaDistribution();
    },
    methods: {
        initChart() {
            this.chart = echarts.init(this.$refs.main, 'chalk')
            echarts.registerMap("myChinaMap", chinaJson)
            this.setMapOption();
            window.addEventListener("onresize", function () {
                this.chart.resize();
            });
        },

        setMapOption(){
            this.chart.setOption(
                {
                    title: {
                        text: '场站分布',
                        left: 20,
                        top: 20,
                    },
                    geo: {
                        type: 'map',
                        map: 'myChinaMap',
                        top: '35%',
                        bottom: '5%',
                        itemStyle: {
                            areaColor: '#2E72BF',
                            borderColor: '#333',
                        },
                        aspectScale:0.75,
                        zoom:1.8,
                        roam:true,
                     /*   scaleLimit: {
                            min: 1.2,
                            max: 30,
                        },*/
                    },
                    // 自定义提示框的内容
                    tooltip: {
                        trigger: 'item',
                        formatter(params) {
                            return (
                                "<div>场站名称: " +
                                params.data.stationName
                                 + " <br>场站编码:  " +
                                params.data.stationNo
                                 + " <br>地理位置:  " +
                                params.data.stationLocation +
                                 "</div>"
                            );
                        },
                        triggerOn: "mousemove",
                        padding: 8,
                        borderWidth: 1,
                        borderColor: '#409eff',
                        backgroundColor: 'rgba(255,255,255,0.7)',
                        textStyle: {
                            color: '#000000',
                            fontSize: 13
                        }

                    },

                    // 散点图
                    series: [
                        {
                            type: 'effectScatter',
                            rippleEffect: {
                                scale: 5,
                                brushType: 'stroke'
                            },
                            coordinateSystem: 'geo',
                            name: '运营中',
                            data: this.status1
                        },
                        {
                            type: 'effectScatter',
                            rippleEffect: {
                                scale: 5,
                                brushType: 'stroke'
                            },
                            coordinateSystem: 'geo',
                            name: '暂停营业',
                            data: this.status2
                        },
                        {
                            type: 'effectScatter',
                            rippleEffect: {
                                scale: 5,
                                brushType: 'stroke'
                            },
                            coordinateSystem: 'geo',
                            name: '维护中',
                            data: this.status3
                        },
                        {
                            type: 'effectScatter',
                            rippleEffect: {
                                scale: 5,
                                brushType: 'stroke'
                            },
                            coordinateSystem: 'geo',
                            name: '歇业中',
                            data: this.status4
                        },
                    ],
                    legend: { // 图例组件
                        left: '5%',
                        bottom: '5%',
                        orient: 'vertical', // 竖着排列
                        textStyle: {
                            color:"#ffffff",
                            fontSize: 13
                        },
                        data: ['运营中', '暂停营业', '维护中', '歇业中'],
                    },
                }
            );
        },

        async getStaDistribution() {
            var that = this;
            that.mapIntervalTimer = setInterval(() => {
                that.getMapData();
            },2000)

        },

        async getMapData(){
            var that = this;
            console.log("================获取地图信息start==============");
            const result = await staDistribution()
            if (result.data.code == 200 && result.data.data!=null) {
                console.log("================获取地图信息sucess==============")
                that.mapData = result.data.data;
                var mapData1 = result.data.data.status1;
                var mapData2 = result.data.data.status2;
                var mapData3 = result.data.data.status3;
                var mapData4 = result.data.data.status4;

                mapData1.forEach(item=>{
                    item.value = [parseFloat(item.longitude),parseFloat(item.latitude)];
                    item.name = item.areaName
                })
                mapData2.forEach(item=>{
                    item.value = [parseFloat(item.longitude),parseFloat(item.latitude)];
                    item.name = item.areaName
                })
                mapData3.forEach(item=>{
                    item.value = [parseFloat(item.longitude),parseFloat(item.latitude)];
                    item.name = item.areaName
                })
                mapData4.forEach(item=>{
                    item.value = [parseFloat(item.longitude),parseFloat(item.latitude)];
                    item.name = item.areaName
                })
                that.status1 = mapData1;
                that.status2 = mapData2;
                that.status3 = mapData3;
                that.status4 = mapData4;
                that.setMapOption();
            }
        }
    },

    beforeDestroy() {
        console.log("================定时器清除start==============")
       // var that = this;
        clearInterval(this.mapIntervalTimer)
        console.log("================定时器清除end==============")
    }
}
</script>

<style lang="less" scoped>
#myChinaMap {
    width: 800px;
    height: 500px;
    margin: 0;
    padding: 0;
    overflow: hidden
}
</style>
