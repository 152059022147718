<template>
    <div id="swaptimes-bar"></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
    props: {
        newBar: {
            type: Object,
            required: true,
            value: () => {
            }
        }
    },
    name: 'swaptimesBar',
    data() {
        return {
            myChart: null,
        }
    },
    watch: {
        newBar: {
            handler(newValue, oldValue) {
                if (this.myChart) {
                    if (newValue) {
                        var option = this.myChart.getOption()
                        option.legend[0].data = newValue.titleList
                        option.xAxis[0].data = newValue.dayList
                        option.series = newValue.arrAll
                        this.myChart.setOption(option)
                    } else {
                        var option = this.myChart.getOption()
                        option.legend[0].data = oldValue.titleList
                        option.xAxis[0].data = oldValue.dayList
                        option.series = oldValue.arrAll
                        this.myChart.setOption(option)
                    }
                } else {
                    this.renderInit()
                }
            },
            deep: true
        }
    },
    mounted() {
        this.renderInit()
    },
    methods: {
        renderInit() {
            let list = this.newBar;
            this.myChart = echarts.getInstanceByDom(document.getElementById('swaptimes-bar'))
            if (!this.myChart) {
                this.myChart = echarts.init(document.getElementById('swaptimes-bar'));
            }
            var option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    show: false,
                    top: '1%',
                    left: '45%',
                    data: list.titleList
                },
                grid: {
                    top: '20%',
                    left: '4%',
                    right: '2%',
                    bottom: '5%',
                    containLabel: true
                },
                xAxis:
                    {
                        type: 'category',
                        data: list.dayList,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#e0e6f1',
                            }
                        },
                        axisLabel: {
                            color: '#ffffff',
                            interval: 0,
                            fontSize: 13,
                            formatter: function (value) {
                                return value
                            }

                        },
                        nameTextStyle: {
                              fontWeight: 300,
                               fontSize: 14
                             }

                        }
                ,
                yAxis: [
                    {
                        type: 'value',
                        name: '换电(次)',
                        axisLabel: {
                            color: '#ffffff'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#e0e6f1'
                            }
                        },
                    }
                ],
                series: [
                    {
                        data: list.arrAll[0].data,
                        type: list.arrAll[0].type,
                        barWidth: '30%',
                        itemStyle: {
                            color: '#fcb41c'
                        }
                    }
                ]
            };
            this.myChart.setOption(option)
            let that = this
            //根据窗口的大小变动图表 --- 重点
            setTimeout(() => {
                window.addEventListener("resize", () => {
                    that.myChart.resize();
                })
            }, 200)
        },
    },
}
</script>

<style lang='less' scoped>
#swaptimes-bar {
    width: 880px;
    height: 290px;
}
</style>
