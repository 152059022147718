<template>
    <!--饼状图-->
    <div id="elec-ratio-pie"></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
    props: {
        newPie: {
            type: Array,
            required: true,
            value: () => {
            }
        }
    },
    name: 'elec-ratio-pie',
    data() {
        return {
            myChart: null
        }
    },
    watch: {
        newPie: {
            handler(newValue, oldValue) {
                if (this.myChart) {
                    if (newValue) {
                        var option = this.myChart.getOption()
                        option.series[0].data = newValue.list
                        this.myChart.setOption(option)
                    } else {
                        var option = this.myChart.getOption()
                        option.series[0].data = oldValue.list
                        this.myChart.setOption(option)
                    }
                } else {
                    this.renderInit()
                }
                this.renderInit()
            },
            deep: true
        },
    },
    mounted() {
        this.renderInit()
    },
    methods: {
        renderInit() {
            let list = this.newPie
            if (this.myChart != null && this.myChart != "" && this.myChart != undefined) {
                this.myChart.dispose();//销毁
            }
            this.myChart = echarts.init(document.getElementById('elec-ratio-pie'),);

            var option = {
                title: {
                    text: this.newPie.name,
                    x: 'center',
                    y: 'top',
                    itemGap: 30,
                    textStyle: {
                        fontSize: 26,
                        fontWeight: 'bolder',
                        color: '#000080'
                    },

                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    show:false,
                    right: '1%',
                    top:'22%',
                    orient: 'vertical',
                    textStyle: {color: '#eee'},
                },
                series: [
                    {
                        name: '能耗情况(KWh)',
                        type: 'pie',
                        /*       radius: ['40%', '60%'],
                               center: ['40%', '50%'],*/
                        radius: '65%',  // 设置环形饼状图， 第一个百分数设置内圈大小，第二个百分数设置外圈大小
                        center: ['50%', '50%'],  // 设置饼状图位置，第一个百分数调水平位置，第二个百分数调垂直位置

                        avoidLabelOverlap: false,

                        itemStyle:{
                            normal:{
                                label:{
                                    color:"#ffffff",
                                    show: true,
                                    formatter: '{b} : ({d}%)'
                                    // formatter: '{b} : {c} ({d}%)'
                                },
                                labelLine :{show:true}
                            },
                        },

                        label: {
                            show: false,
                            formatter: '{c}',
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '18',
                                fontWeight: 'normal',
                                formatter: '{d}%',
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        color: ['#41c6f5','#FFD700','#e10505', '#C0FF3E'],
                        data: list
                    }
                ]
            };

            this.myChart.setOption(option)
            let that = this
            //根据窗口的大小变动图表 --- 重点
            setTimeout(() => {
                window.addEventListener("resize", () => {
                    that.myChart.resize();
                })
            }, 200)
        }
    },
}
</script>

<style lang='less' scoped>
#elec-ratio-pie {
    width: 501px;
    height: 200px;
}
</style>
