<template>
  <div id="elec-statistics-bar"></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  props: {
    newBar: {
      type: Object,
      required: true,
      value: () => {
      }
    }
  },
  name: '',
  data() {
    return {
      myChart: null
    }
  },
  watch: {
    newBar: {
      handler(newValue, oldValue) {
        if (this.myChart) {
          if (newValue) {
            var option = this.myChart.getOption()
            option.legend[0].data = newValue.yearList
            option.xAxis[0].data = newValue.monthList
            option.yAxis = [
              {
                type: 'value',
                name: '单位:kw/h'
              }
            ],
            option.series = newValue.arrAll
            this.myChart.setOption(option)

            /**
             var option = this.myChart.getOption()
             option.xAxis[0].data = newValue.item
             option.yAxis[0].name = newValue.unit
             option.title[0].text = newValue.name
             // option.legend[0].data = newValue.titleList
             option.series[0].name = newValue.title1
             option.series[1].name = newValue.title2
             option.series[2].name = newValue.title3
             option.series[0].data.value = 100  //newValue.list1.mt
             option.series[1].data.value = 200  //newValue.list2.mt
             option.series[2].data.value = 300  //newValue.list3.mt
             // option.series = newValue.list
             this.myChart.setOption(option)
             **/

          } else {
            var option = this.myChart.getOption()
            option.legend[0].data = oldValue.yearList
            option.xAxis[0].data = oldValue.monthList
            option.series = oldValue.arrAll
            this.myChart.setOption(option)
            /*
            var option = this.myChart.getOption()
            option.xAxis[0].data = oldValue.item
            option.title[0].text = oldValue.name
            option.yAxis[0].name = oldValue.unit
             // option.legend[0].data = oldValue.titleList
            option.series[0].name = newValue.title1
            option.series[1].name = newValue.title2
            option.series[2].name = newValue.title3
            option.series[0].data = newValue.list1
            option.series[1].data = newValue.list2
            option.series[2].data = newValue.list3
            this.myChart.setOption(option)
            */
          }
        } else {
          this.renderInit()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.renderInit()
  },
  methods: {
    renderInit() {
      let list = this.newBar
      if (this.myChart != null && this.myChart != "" && this.myChart != undefined) {
        this.myChart.dispose();//销毁
      }
      this.myChart = echarts.init(document.getElementById('elec-statistics-bar'));
      var option = {
        title: {
          text: list.name,
          left: '0%',
          top: '1%',
          textStyle: {
            color: '#5d5d5d'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          top: '1%',
          right: '8%',
            itemGap: 16,
          // data: list.titleList
        },
        grid: {
          top: '20%',
          left: '2%',
          right: '2%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          show: true,
          orient: 'horizontal',
          left: '20%',
          top: 'top',
          feature: {
            mark: {show: true},
            // dataView: {show: true, readOnly: false},
            magicType: {show: true, type: ['line', 'bar'],title:{ line:'切换为折线图',bar:'切换为柱状图'}},
            // restore: {show: true},
            // saveAsImage: {show: true}
          }
        },
        xAxis: [
          {
            type: 'category',
            data: list.item,
            textStyle: {
              left: '3%',
            },
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#dde4f1'
              }
            },
            axisLabel: {
              color: '#333'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: list.unit,
            axisLabel: {
              formatter: '{value}'
            }
          }
        ],
        series: [
          {
            name: list.title1,
            type: 'bar',
            barGap: 0,
            // label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: list.list1
          },
          {
            name: list.title2,
            type: 'bar',
            // label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: list.list2
          },
          {
            name: list.title3,
            type: 'bar',
            // label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: list.list3
          },
        ],
          color:['#0D6CE4','#FA9600','#19B21E'],
      };
      this.myChart.setOption(option)
      let chart = this.myChart
      setTimeout(() => {
        window.addEventListener("resize", () => {
          chart.resize();
        })
      }, 200)
    }
  },
}
</script>

<style lang='less' scoped>
#elec-statistics-bar{
  width: 100%;
  height: 300Px;
}
</style>
