<template>
    <div id="operatorTeamTree" class="operatorTeamTree">
        <div class="change-line">
            <div class="change-in" @click="isShowValue"></div>
        </div>
        <div class="searchArea" id="searchPanel">
            <el-input class="searchInput"  v-model="filterText"
                      placeholder="运营商/车队名称" clearable size="middle" prefix-icon="el-icon-search"
            />
        </div>

        <!--
               <div class="onlineBox">
              <el-radio-group v-model="onlineStatus" @change="changeStatusFilter">
                <el-radio label="0">全部({{allNum}})</el-radio>
                <el-radio label="1">在线({{onlineNum}})</el-radio>
                <el-radio label="2">离线({{offlineNum}})</el-radio>
              </el-radio-group>
            </div>

           -->

        <!--open-->
        <el-tree
            class="filter-tree"
            :data="treeData.operatorTeamOptions"
            :props="treeData.defaultProps"
            :expand-on-click-node="false"
            :filter-node-method="filterNode"
            ref="opeTree"
            node-key="id"
            @node-click="handleNodeClick"
            @node-contextmenu="rightClick"
            :render-content="renderContent"
            :default-expand-all="treeData.defaultExpandAll"
        />
        <div id="perTreeMenu" v-if="tmDisplay" class="tree_menu" :style="{...rightMenu}">
            <ul>
                <li><i class="el-icon-view"></i> 查看轨迹</li>
            </ul>
        </div>
        <!--
              :default-expanded-keys="[-1]"
         highlight-current
        :render-content="renderContent"
           :render-content="renderContent"
            show-checkbox 复选框
          @check="handleNodeClick" 复选框事件
        default-expand-all 是否展开所有节点
        @node-click="handleNodeClick"点击节点，  加了show-checkbox变成选择项， @check-change选中节点-->


    </div>
</template>

<script>
import Vue from 'vue';
import {Tree} from 'element-ui'
Vue.use(Tree);

import {operatorTeamTree} from "@/utils/operatorApi";
export default {
    name: "operatorTeamTree",
    components: {},
    data() {
        return {
            filterText: '',
            hasSearch: false,
            treeData: {
                operatorTeamOptions: undefined,  //运营商-车队树选项
                defaultProps: {
                    children: "children",
                    label: "label"
                },
                operatorTeamNameTree: "",
                operatorTeamIdTree: null,
                defaultExpandAll: true,
            },
            isShow:true,
            tmDisplay:false,
            rightMenu:null,
        }
    },

    created() {
        this.getOperatorTeamTree();
    },

    watch: {
        filterText(val) {
            this.$refs.opeTree.filter(val);
        }
    },


    methods: {
        rightClick(e,data,node,comp){
            console.log('e:',e,'data',data)
            this.rightMenu = {top:e.pageY+'px',left:e.pageX+'px'}
            this.tmDisplay = true
            const self = this
            document.onclick=function(ev){
                if(ev.target!==document.getElementById('perTreeMenu')){
                    self.tmDisplay = false
                }
            }
        },
        isShowValue(){
            this.isShow=!this.isShow
            this.$emit("isShow", this.isShow);
        },
        /** 查询运营商--车队下拉树结构 */
        async getOperatorTeamTree() {
            try {
                this.treeData.operatorTeamOptions = [];
                let res = await operatorTeamTree();
                //let res = await operatorTeamList()

                if (res.data.code !== 200) return this.$msg.error("获取运营商信息失败");
                this.treeData.operatorTeamOptions.push(res.data.data)
            } catch (error) {
            }
        },

        // 筛选节点
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },


        // 节点单击事件
        async handleNodeClick(data) {
            this.treeData.operatorTeamIdTree = data.id;
            this.treeData.operatorTeamNameTree = data.label;
            this.toTreeDataRes();
        },

        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },

        // tree添加元素
        renderContent(h, {node, data, store}) {
            return (
                <span style="margin-top:8px;margin-bottom:5px;">
              <span>
                <span title={node.label}>{node.label}</span>
              </span>
              </span>
            );
        },

        toTreeDataRes() {
            this.$emit('emitToParent', this.treeData)
        },

        hidePanel(event) {
            let pannel = document.getElementById('operatorTree')
            if (pannel) {
                if (!pannel.contains(event.target)) {
                    this.hasSearch = false
                }
            }
        },

    }

}

</script>

<style  lang='less' scoped>
.operatorTeamTree{
    width: 200px;
    height: 796px;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: scroll;
    border-right: #d3dce6 1px solid;
}

/*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
    width: 6px; /*对垂直流动条有效*/
    height: 6px; /*对水平流动条有效*/
}

/*定义滚动条的轨道颜色、内阴影及圆角*/
::-webkit-scrollbar-track{
    border-radius: 4px;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
    /* background-color: rosybrown; */
}

/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb{
    border-radius: 8px;
    background-color: #DDDEE0;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
}

/*定义滑块悬停变化颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb:hover{
    //background-color: #62b2ff; //#C7C9CC;
}

/*定义两端按钮的样式*/
::-webkit-scrollbar-button {
    //background-color: #d3dce6;
}

/*定义右下角汇合处的样式*/
::-webkit-scrollbar-corner {
    //background: khaki;
}
.searchArea {
    width: 100%;
    vertical-align: middle;
    position: relative;
    padding: 8px 16px;
    /deep/.el-input--suffix .el-input__inner{
        padding-right: 0;
    }
    /deep/.el-input__inner,/deep/.el-input__icon{
        height: 32px;
        line-height: 32px;
    }
}

.searchInput {
    width: 100%;
    margin: 0 auto;
}


.filter-tree {
    width: 100%;
    height: auto;
    overflow: hidden;
    overflow-y: auto;
}
.change-line{
    height: 36px;
    line-height: 36px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 16px;
    .change-in{
        width: 12px;
        height: 12px;
        background: url("~@/assets/image/iconinSide.svg") no-repeat;
        background-size: 100% 100%;
    }
}
.tree_menu{
    position: fixed;
    display: block;
    z-index: 20000;
    background-color: #fff;
    padding:5px 0;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1);

    ul{
        margin:0;
        padding:0;
    }
    ul li{
        list-style: none;
        margin:0;
        padding:0 15px;
        font-size: 14px;
        line-height: 30px;
        cursor: pointer;
    }
    ul li:hover{
        background-color: #ebeef5
    }
}
</style>
<style lang="less">
.operatorTeamTree {
    .el-tree{
        padding-top: 0;
    }
    .el-tree-node {
        margin: 0;
        padding: 0;
    }
    .el-tree-node.is-current > .el-tree-node__content{
        height: 40px;
        line-height: 40px;
        background: #F1F7FF;
        border-left:0px solid #0D6CE4;
        font-size: 14px;
        font-weight: 400;
        color: #0D6CE4;

    }
    .el-tree-node__content{
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        margin: 0;
    }
    .el-tree-node .el-tree-node__children{
        padding: 0px;
        margin: 0px;
    }
    .el-tree-node > .el-tree-node__content:hover{
        background: #F1F7FF;
        color: #0D6CE4;
    }
}

</style>
