import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/baseJS/element'
// import { Message} from 'element-ui'
// 引入ElementUI组件库
import ElementUI from 'element-ui';
//引入ElementUI的所有样式
import 'element-ui/lib/theme-chalk/index.css';
import { toExcel } from "@/utils/exportExcel"
import * as myStore from "@/utils/stroage";
import moment from 'moment'
import BaiduMap from 'vue-baidu-map'
import './assets/font1/iconfont.css'
import './assets/font/iconfont.css'
// 公共的样式
import './assets/style/common.less'

import './assets/js/rests'
import './assets/icons' // icon
import './assets/home/index.less'
import '@/assets/font/font.css';
import directive from './directive' // directive
import uploader from 'vue-simple-uploader'
Vue.config.productionTip = false
import $ from "jquery";
//使用ElementUI
Vue.use(ElementUI)
import {selectDictDataByType as getDicts} from '@/utils/dict'
Vue.prototype.getDicts = getDicts
import DictData from '@/components/DictData'
DictData.install()
import DictTag from '@/components/DictTag'
import AMapLoader from "@amap/amap-jsapi-loader";
Vue.component('DictTag', DictTag)
window._AMapSecurityConfig = {
    securityJsCode: 'eb74eb1a1f45d75991e00aabc4b95fd5',
}

Vue.prototype.$download = (selectList,columns,name)=> {
  let thDataList = []; // 存储表头
  let filterVal = []; // 存储表单数据
  if (selectList.length !== 0) {
    //  判断选择框是否选中数据
    const dataList = selectList; // 获取到当前选择框选择到的数组
    for (let i = 0; i < columns.length; i++) {
      // 数组循环
      thDataList.push(columns[i].label); // 循环得到 el-table中的label值，作为Excel表格的表头
      filterVal.push(columns[i].prop); // 循环得到 el-table中的porp值， 作为Excel表格的数据
    }
    const th = thDataList;
    const data = dataList.map((v) => filterVal.map((k) => v[k])); // 这里使用数据筛选
    const [fileName, fileType, sheetName] = [name, "xlsx", "sheet1"]; // 这里的数组中传递三个参数，分别是Excel的文件名称， Excel的文件类型后缀， 第三个参数不会显示，但是必须要存在
    toExcel({ th, data, fileName, fileType, sheetName }); // 最后把数据传递过去
  } else {
    Message.info("请选择至少一条数据进行操作！");
  }
}

Vue.prototype.$dateFormat = (row, column) => {
  const date = row[column.property];
  if (date === undefined || date === null) {
    return "";
  }
  // 这里的格式根据需求修改
  return moment(date).format("YYYY-MM-DD");
}

Vue.prototype.$dateFormat1 = (row, column) => {
    const date = row[column.property];
    if (date === undefined || date === null) {
        return "";
    }
    // 这里的格式根据需求修改
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
}

Vue.prototype.$toDecimal2 = (x,column)=>{
  let y = null
  if(Object.prototype.toString.call(x) === '[object Object]'){
    y = x[column.property]
  }else{
    y = x
  }
  if(y.toString().indexOf('.') !== -1){
    return y
  }else{
    return y = y + '.00'
  }
}

Vue.prototype.storage =(name,data)=>{
  let list = JSON.parse(getItem(name))
  let keys = Object.keys(data)
  if(list){
    list[0].record = list[0].index === 3 || list[0].index === 6 ?true:false

    const state = keys.every(item=>{
      return list[2][item] === data[item]
    })
    if(state){
      list[0].index += 1
      list[1] = list[0].record?list[1] = list[2]:list[1]
      setItem(name,JSON.stringify(list))
    }else{
      list[2] = data
      setItem(name,JSON.stringify(list))
    }

    if(list[0].index > 3 && list[0].index < 6){

      return list[1]
    }else if(list[0].index === 6){
      list[0].index = 4
      list[1] = list[2]
      setItem(name,JSON.stringify(list))
    }
  }else{
    setItem(name,JSON.stringify([{index:1,record:false},data,data]))
  }


}
Vue.prototype.changeDetSelect = (key,options)=>{
  let arr = []//在递归是操作的数组
  let returnArr = []//存放结果的数组
  let depath = 0//定义层级
  // 定义递归函数
  function childrenEach(childrenData, depthN) {
    for (var j = 0; j < childrenData.length; j++) {
      let  depth = depthN; // 将执行的层级赋值 到 全局层级
      arr[depthN] = (childrenData[j].value);
      if (childrenData[j].value == key) {
        returnArr = arr.slice(0, depthN+1); //将目前匹配的数组，截断并保存到结果数组，
        break
      } else {
        if (childrenData[j].children) {
          depth ++;
          childrenEach(childrenData[j].children, depth);
        }
      }
    }
    return returnArr;
  }
  return childrenEach(options, depath)
}

Vue.prototype.getTableData = (page = 1, pageSize = 10, totalData = [])=>{
  const { length } = totalData;
  const tableData = {
    data: [],
    page,
    pageSize,
    length,
  };
  if (pageSize >= length) { //pageSize大于等于总数据长度，说明只有1页数据或没有数据
    tableData.data = totalData;
    tableData.page = 1;//直接取第一页
  } else { //pageSize小于总数据长度，数据多余1页
    const num = pageSize * (page - 1);//计算当前页（不含）之前的所有数据总条数
    if (num < length) { //如果当前页之前所有数据总条数小于（不能等于）总的数据集长度，则说明当前页码没有超出最大页码
      const startIndex = num;//当前页第一条数据在总数据集中的索引
      const endIndex = num + pageSize - 1;//当前页最后一条数据索引
      tableData.data = totalData.filter((_, index) => index >= startIndex && index <= endIndex);//当前页数据条数小于每页最大条数时，也按最大条数范围筛取数据
    } else { //当前页码超出最大页码，则计算实际最后一页的page，自动返回最后一页数据
      const size = parseInt(length / pageSize); //取商
      const rest = length % pageSize; //取余数
      if (rest > 0) { //余数大于0，说明实际最后一页数据不足pageSize，应该取size+1为最后一条的页码
        tableData.page = size + 1;//当前页码重置，取size+1
        tableData.data = totalData.filter((_, index) => index >= (pageSize * size) && index <= length);
      } else if (rest === 0) { //余数等于0，最后一页数据条数正好是pageSize
        tableData.page = size;//当前页码重置，取size
        tableData.data = totalData.filter((_, index) => index >= (pageSize * (size - 1)) && index <= length);
      } //注：余数不可能小于0
    }
  }
  return tableData;
}
// 地图加载器
Vue.prototype.AMap=(resolve)=>{
    AMapLoader.load({
        key: "42d06ee580cf9c96a2cd211611e44f7b", //key值是key值 和安全密钥不同
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.MarkerCluster', 'AMap.InfoWindow','AMap.MouseTool',
            'AMap.Polyline', 'AMap.MoveAnimation', 'AMap.GeometryUtil',"AMap.PlaceSearch","AMap.Geocoder","AMap.PolyEditor","AMap.ContextMenu"] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((AMap) => {
        window.AMap;
        window.AMapUI;
            resolve()
            return AMap
        }).catch((e) => {
            console.log(e, 'catch');
        });
}
Vue.prototype.AMapUI=(resolve,reject)=>{
    if (window.AMapUI) {
        resolve(window.AMapUI)
    } else {
        var scriptUI = document.createElement('script')
        scriptUI.type = 'text/javascript'
        scriptUI.src = 'http://webapi.amap.com/ui/1.1/main.js?v=1.1.1'
        scriptUI.onerror = reject

        scriptUI.onload = function(su){
            resolve(window.AMapUI)
        };
        document.head.appendChild(scriptUI)
    }
}

Vue.use(BaiduMap, {
  // ak: 'OCTwToERoTpokK80wsvvggQQrdVzfUNL'
  ak: '4sL8sZoYaCFCvpSMrUmwwxz87pIzLjSa'
})

Vue.use(directive)
Vue.use(uploader)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

