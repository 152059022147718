<template>
  <div id="original-line"></div>
</template>
 
<script>
import * as echarts from 'echarts'
export default {
    props:{
      newArray:{
          type:Object,
          required:true,
          value:()=>{}
      }
    },
       name:'originCompoment',
       data(){ 
       return{
        myChart:null
       }
    },
    watch:{
       newArray:{
           handler(newValue,oldValue){
              if(this.myChart){
                 if(newValue){
                      var option = this.myChart.getOption()
                    option.xAxis[0].data= newValue.totalList
                    option.series = newValue.seriesList
                    this.myChart.setOption(option)
                 }else{
                    var option = this.myChart.getOption()
                   option.xAxis[0].data= oldValue.totalList
                    option.series = oldValue.seriesList
                    this.myChart.setOption(option)
                 }
              }else{
                this.renderInit()
              }
           },
           deep:true
       }
    },
   mounted() {
         this.$nextTick(()=>{
             this.renderInit()
         })
   },
   methods: {
       renderInit(){
              this.myChart = echarts.getInstanceByDom(document.getElementById('original-line'));
              if(!this.myChart){
                this.myChart = echarts.init(document.getElementById('original-line'));
            }
              var option = {
                    title:{
                        left: '1%',
                        top:'-1%',
                        text: '累计值',
                         textStyle:{
                            fontWeight:'400',
                            fontSize:'15'
                        }
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis:{
                        type:'category',
                        data: this.newArray.totalList,
                        boundaryGap:false,
                        axisTick:{
                            show:false
                        },
                         axisLine:{
                             show:true,
                            lineStyle:{
                                color:'#f3f3f3'
                            }
                        },
                        axisLabel:{
                             color:'#333'
                        }
                    },
                    yAxis:{
                        axisLine:{
                            show:false,
                            lineStyle:{
                                color:'#f3f3f3'
                            }
                        },
                        axisLabel:{
                             color:'#333'
                        },
                        splitLine:{
                            lineStyle:{
                                color:'#f3f3f3'
                            }
                        }
                    },
                    grid: {
                        top:'15%',
                        left:'4%',
                        right:'4%',
                        bottom: '10%'
                    },
                    series:  this.newArray.seriesList
                };
              let myChart = this.myChart
             myChart.setOption(option);
              //根据窗口的大小变动图表 --- 重点
                setTimeout( ()=>{
              window.addEventListener("resize", () => { myChart.resize(); })
	        },200)
       }
   

   },
}
</script>
 
<style lang='less' scoped>
 #original-line{
     width: 100%;
     height: 100%;
 }
</style>