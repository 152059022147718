<template>
  <div id="branch-bar"></div>
</template>
 
<script>
import * as echarts from 'echarts'
export default {
    props:{
      newObj:{
          type:Object,
          required:true,
          value:()=>{}
      }
    },
       name:'',
       data(){ 
       return{
        myChart:null
       }
    },
   watch:{
       newObj:{
           handler(newValue,oldValue){
              if(this.myChart){
                if(newValue){
                    var option = this.myChart.getOption()
                    option.xAxis[0].data = newValue.item
                    option.yAxis[0].name = newValue.unit
                    option.title[0].text = newValue.name
                    option.legend[0].data = newValue.titleList
                    option.series = newValue.list
                    this.myChart.setOption(option)
                }else{
                    var option = this.myChart.getOption()
                    option.xAxis[0].data = oldValue.item
                    option.title[0].text = oldValue.name
                    option.yAxis[0].name = oldValue.unit
                     option.legend[0].data = oldValue.titleList
                    option.series = oldValue.list
                    this.myChart.setOption(option)
                }
              }else{
                this.renderInit()
              }
           },
           deep:true 
       }
   }, 
  mounted() {
      this.renderInit()
  },
  methods: {
      renderInit(){
          this.myChart = echarts.getInstanceByDom(document.getElementById('branch-bar'))
            if(!this.myChart){
                this.myChart = echarts.init(document.getElementById('branch-bar'));
            }
           var option = {
                        title:{
                           text:this.newObj.name,
                           left:'0%',
                           top:'1%',
                           textStyle:{
                              color:'#5d5d5d'
                          }
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                                type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                            }
                        },
                        legend:{
                            top:'1%',
                            right:'8%',
                             data:this.newObj.titleList
                        },
                        grid: {
                            top:'20%',
                            left: '3%',
                            right: '2%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: this.newObj.item,
                                 axisTick: {
                                    show:false
                                    }, 
                                    axisLine:{
                                        lineStyle:{
                                            color:'#dde4f1'
                                        }
                                },
                                axisLabel:{
                                    color:'#333'
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name:this.newObj.unit,
                                axisLabel:{
                                    formatter:'{value}'
                                }
                            }
                        ],
                        series: this.newObj.list
                    };

            this.myChart.setOption(option)
        let chart = this.myChart
         setTimeout( ()=>{
              window.addEventListener("resize", () => { chart.resize(); })
            },200)
      }
  },
}
</script>
 
<style lang='less' scoped>
 #branch-bar{
     width: 100%;
     height: 400Px;
 }
</style>