import * as myStore from "@/utils/stroage";

export default {
  //钩子指令
  inserted(el, binding, vnode) {
    //el: 指定作用在元素上

    //获取使用指令时传递的值
    const { value } = binding
    const all_code = "*:*:*";

    // 获取用户当前所拥有的所有按钮权限
    //let codes  = JSON.parse(window.sessionStorage.getItem("function-code") || "[]")
    let codes = myStore.sessionGetItem("function-code") || [];
    //console.log("-------codes----after------((",codes,"))---------------")
    //  指令传递了值
    if (value && value instanceof Array && value.length > 0) {
      const codeFlag = value

      //查询按钮权限列表里面有没有传递的值
      const hasCodes = codes.some(code => {
        return all_code === code || codeFlag.includes(code)
      })

      // 按钮权限列表里面没有传递的值
      if (!hasCodes) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      // 指令没有传递值
      throw new Error(`请设置操作权限标签值`)
    }
  }
}
