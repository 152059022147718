import hasCode from './permission/hasCode'

const install = function(Vue) {
  Vue.directive('hasCode', hasCode)
}

if (window.Vue) {
  window['hasCode'] = hasCode
  Vue.use(install); // eslint-disable-line
}

export default install
