<template>
  <div id="basis-bar"></div>
</template>

<script>
  import * as echarts from 'echarts'
  export default {
    props:{
      newBar:{
        type:Object,
        required:true,
        value:()=>{}
      }
    },
    name:'stationBar',
    data(){
      return{
        myChart:null
      }
    },
    watch:{
      newBar:{
        handler(newValue,oldValue){
          if(this.myChart){
            if(newValue){
              var option = this.myChart.getOption()
              option.legend[0].data = newValue.yearList
              option.xAxis[0].data = newValue.monthList
              option.series = newValue.arrAll
              this.myChart.setOption(option)
            }else{
              var option = this.myChart.getOption()
              option.legend[0].data = oldValue.yearList
              option.xAxis[0].data = oldValue.monthList
              option.series = oldValue.arrAll
              this.myChart.setOption(option)
            }
          }else{
            this.renderInit()
          }
        },
        deep:true
      }
    },
    mounted() {

      this.renderInit()
    },
    methods: {
      renderInit(){
        let list = this.newBar
        this.myChart = echarts.getInstanceByDom(document.getElementById('basis-bar'))
        if(!this.myChart){
          this.myChart = echarts.init(document.getElementById('basis-bar'));
        }
        var  option = {
          tooltip: {
            trigger: 'axis'
          },
            toolbox: {
                show: true,
                orient: 'horizontal',
                left: '20%',
                top: 'top',
                feature: {
                    mark: {show: true},
                    // dataView: {show: true, readOnly: false},
                    magicType: {show: true, type: ['line', 'bar'],title:{ line:'切换为折线图',bar:'切换为柱状图'}}
                    // restore: {show: true},
                    // saveAsImage: {show: true}
                }
            },
            legend: {
            top:'1%',
            left:'70%',
              itemGap: 16,
            data: list.yearList
          },

          grid: {
            top:'20%',
            left: '3%',
            right: '2%',
            bottom: '5%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data:list.monthList,
              axisPointer: {
                type: 'shadow'
              },
              axisTick:{
                show:false
              },
              axisLine:{
                lineStyle:{
                  color:'#e0e6f1'
                }
              },
              axisLabel:{
                color:'#333'
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '换电次数(次)'
            }
          ],
          series: list.arrAll,
          color:['#0D6CE4','#FA9600','#19B21E'],
        };
        this.myChart.setOption(option)
        let that = this
        //根据窗口的大小变动图表 --- 重点
        setTimeout( ()=>{
          window.addEventListener("resize", () => { that.myChart.resize(); })
        },200)
      }
    },
  }
</script>

<style lang='less' scoped>
  #basis-bar{
    width: 100%;
    height: 319px;
  }
</style>
