<template>
    <div id="basis"></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
    props: {
        newPie: {
            type: Object,
            required: true,
            value: () => {
            }
        }
    },
    name: 'stationPie',
    data() {
        return {
            myChart: null
        }
    },
    watch: {
        newPie: {
            handler(newValue, oldValue) {
                if (this.myChart) {
                    if (newValue) {
                        var option = this.myChart.getOption()
                        option.series[0].data = newValue.list
                        this.myChart.setOption(option)
                    } else {
                        var option = this.myChart.getOption()
                        option.series[0].data = oldValue.list
                        this.myChart.setOption(option)
                    }
                } else {
                    this.renderInit()
                }
            },
            deep: true
        }
    },
    mounted() {
        this.renderInit()
    },
    methods: {
        renderInit() {
            let list = this.newPie;
            this.myChart = echarts.getInstanceByDom(document.getElementById('basis'))
            if (!this.myChart) {
                this.myChart = echarts.init(document.getElementById('basis'));
            }
            var option = {
                title: {
                    text: list.name,
                    top: '1%',
                    left: '1%',
                    textStyle: {
                        color: '#5d5d5d'
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                grid: {
                    left: '0%',
                    top: '1%',
                    right: '1%',
                    bottom: '1%'
                },
                legend: {
                    right: '5%',
                    top: 'center',
                    orient: 'vertical',
                    itemGap: 16,
                    textStyle: {
                        fontSize:14,
                        margin:[0,0,16,0]
                    }
                },
                color:['#0D6CE4','#FA9600','#19B21E'],
                series: [
                    {
                        name: '换电次数',
                        type: 'pie',
                        radius: ['35%', '55%'],
                        center: ['35%', '50%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center',
                        },
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2,
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '20',
                                formatter: '{c}%'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: list.list
                    }
                ]
            };
            this.myChart.setOption(option)
            let that = this
            //根据窗口的大小变动图表 --- 重点
            setTimeout(() => {
                window.addEventListener("resize", () => {
                    that.myChart.resize();
                })
            }, 200)
        }
    },
}
</script>

<style lang='less' scoped>
#basis {
    width: 100%;
    height: 319px;
}
</style>
