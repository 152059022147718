<template>
  <div id="branch-bar"></div>
</template>
 
<script>
import * as echarts from 'echarts'
export default {
    props:{
       newBar:{
           type:Object,
           required:true,
           value:()=>{}
       }
    },
       name:'branchBar',
       data(){ 
       return{
        myChart:null
       }
    },
   watch:{
      newBar:{
          handler(newValue,oldValue){
              if(this.myChart){
                 if(newValue){
                  var option = this.myChart.getOption()
                   option.series = newValue.list
                    option.title[0].text = newValue.name
                    option.legend[0].data = newValue.itemList
                    option.xAxis[0].data = newValue.areaNameList
                    this.myChart.setOption(option)
                 }else{
                   var option = this.myChart.getOption()
                     option.series = oldValue.list
                    option.title[0].text = oldValue.name
                    option.legend[0].data = oldValue.itemList
                    option.xAxis[0].data = oldValue.areaNameList
                    this.myChart.setOption(option)
                 }
              }else{
                  this.renderInit()
              }
          },
          deep:true
      }
   } ,
  mounted() {
      this.renderInit()
  },
  methods: {
      renderInit(){
          this.myChart = echarts.getInstanceByDom(document.getElementById('branch-bar'))
            if(!this.myChart){
                this.myChart = echarts.init(document.getElementById('branch-bar'));
            }
            const item = this.newBar.itemList
           var option = {
                  title:{
                          text:this.newBar.name,
                          top:'3%',
                          left:'2%',
                          textStyle:{
                              color:'#5d5d5d'
                          }
                        },
                        legend:{
                            top:'3%',
                           data:item
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                                type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                            }
                        },
                        grid: {
                            left: '1%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: this.newBar.areaNameList,
                                axisTick: {
                                    show:false
                                },
                                axisLine:{
                                    lineStyle:{
                                        color:'#e0e6f1'
                                    }
                                },
                                axisLabel:{
                                    color:'#333'
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value'
                            }
                        ],
                        series: this.newBar.list
                    };

            this.myChart.setOption(option)
        let chart = this.myChart
         setTimeout( ()=>{
              window.addEventListener("resize", () => { chart.resize(); })
            },200)
      }
  },
}
</script>
 
<style lang='less' scoped>
 #branch-bar{
     width: 100%;
     height: 400Px;
 }
</style>