<template>
  <el-card>
    <el-row>
      <div class="vehicle-title">车辆信息管理 <i @click="$router.go(-1)" class="iconfont icon-fanhui"></i></div>
    </el-row>
    <el-row class="vehicleMsg">
      <ul class="basics-ul">
        <li>
          <div>
            <span>车辆所有人：</span>
            <span>{{ vehicleData.possessor }}</span>
          </div>
          <div>
            <span>审核进度：</span>
            <span v-if="vehicleData.auditState === 0"
            ><i class="auditState" style="background-color: #4d4d4d"></i
            >未注册</span
            >
            <span v-else-if="vehicleData.auditState === 1"
            ><i class="auditState" style="background-color: #ee9e4d"></i
            >审核中</span
            >
            <span v-else-if="vehicleData.auditState === 2"
            ><i class="auditState" style="background-color: #2981e3"></i
            >审核通过</span
            >
            <span v-else
            ><i class="auditState" style="background-color: #d90013"></i
            >审核不通过</span
            >
          </div>
          <div>
            <span>联系号码：</span>
            <span>{{ vehicleData.rfidId }}</span>
          </div>
        </li>
        <li>
          <div>
            <span>车牌号：</span>
            <span>{{ vehicleData.carNo }}</span>
          </div>
          <div>
            <span>车牌品牌：</span>
            <span>{{ vehicleData.brand }}</span>
          </div>
          <div>
            <span>车辆型号：</span>
            <span>{{ vehicleData.carModel }}</span>
          </div>
          <div>
            <span>车辆性质：</span>
            <span v-if="vehicleData.carModel === 1">私人车辆</span>
            <span v-else-if="vehicleData.carModel === 2">运营车辆</span>
            <span v-else>平台自有车辆</span>
          </div>
          <div>
            <span>车辆外观：</span>
            <el-avatar shape="square" :size="100" fit="fill" :src="vehicleData.carApc"></el-avatar>
          </div>
        </li>
        <li>
          <div>
            <span>车辆VIN码：</span>
            <span>{{ vehicleData.carVin }}</span>
          </div>
          <div>
            <span>底盘号：</span>
            <span>{{ vehicleData.chassisNumber }}</span>
          </div>
          <div>
            <span>电动机号：</span>
            <span>{{ vehicleData.engineNumber }}</span>
          </div>
          <div>
            <span>车辆识别号：</span>
            <span>{{ vehicleData.identifyCode }}</span>
          </div>
          <div>
            <span>购买日期：</span>
            <span>{{ vehicleData.purchaseDate }}</span>
          </div>
        </li>
      </ul>
    </el-row>
    <el-row>
      <el-tabs v-model="activeName" type="card" @tab-click="changeTabs" >
        <!-- 换电次数 -->
        <el-tab-pane label="换电次数" name="0">
          <el-row class="electric">
            <el-col :span="3">
              <div><i class="iconfont icon-chongdianzhong"></i></div>
              <div>
                <span>换电次数</span>
                <div>
                  <span>{{classList.yearCount}}</span>
                  <span>次</span>
                </div>
              </div>
            </el-col>
            <el-col :span="3">
              <div><i class="iconfont icon-chongdianzhong"></i></div>
              <div>
                <span>今日换电次数</span>
                <div>
                  <span>{{classList.dayCount}}</span>
                  <span>次</span>
                </div>
              </div>
            </el-col>
            <el-col :span="3">
              <div><i class="iconfont icon-chongdianzhong"></i></div>
              <div>
                <span>本月换电次数</span>
                <div>
                  <span>{{classList.monthCount}}</span>
                  <span>次</span>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row type="flex" align="middle" justify="space-between">
            <div>换电次数统计</div>
            <div class="timeSelect">
              <div class="time1">
                <el-select v-model="yearValue" @change="dateValue = ''" placeholder="请选择">
                  <el-option
                    v-for="item in timeoptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="time2" >
                <Quarter @quarter="quarterClick" v-if="yearValue === 4"></Quarter>
                <el-date-picker
                  @change="pickerChange"
                  v-else
                  v-model="dateValue"
                  :type="timeType"
                  :format="timeMat"
                  :placeholder="timeholder"></el-date-picker>
              </div>
            </div>
          </el-row>
          <div class="echarts-statistics">
            <reuse-echarts v-if="activeName === '0'" :optionsList="newObj" :timeList="timeState"></reuse-echarts>
          </div>
        </el-tab-pane>
        <!-- 换电消费 -->
        <el-tab-pane label="换电消费" name="1">
          <el-row class="electric">
            <el-col :span="3">
              <div><i class="iconfont icon-chongdianzhong"></i></div>
              <div>
                <span>消费金额</span>
                <div>
                  <span>{{classList.yearCount}}</span>
                  <span>元</span>
                </div>
              </div>
            </el-col>
            <el-col :span="3">
              <div><i class="iconfont icon-chongdianzhong"></i></div>
              <div>
                <span>今日消费金额</span>
                <div>
                  <span>{{classList.dayCount}}</span>
                  <span>元</span>
                </div>
              </div>
            </el-col>
            <el-col :span="3">
              <div><i class="iconfont icon-chongdianzhong"></i></div>
              <div>
                <span>本月消费金额</span>
                <div>
                  <span>{{classList.monthCount}}</span>
                  <span>元</span>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row type="flex" align="middle" justify="space-between">
            <div>消费金额统计</div>
            <div class="timeSelect">
              <div class="time1">
                <el-select v-model="yearValue" @change="dateValue = ''" placeholder="请选择">
                  <el-option
                    v-for="item in timeoptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="time2" >
                <Quarter @quarter="quarterClick" v-if="yearValue === 4"></Quarter>
                <el-date-picker
                  @change="pickerChange"
                  v-else
                  v-model="dateValue"
                  :type="timeType"
                  :format="timeMat"
                  :placeholder="timeholder"></el-date-picker>
              </div>
            </div>
          </el-row>
          <div class="echarts-statistics">
            <reuse-echarts v-if="activeName === '1'" :optionsList="newObj" :timeList="timeState"></reuse-echarts>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </el-card>
</template>

<script>
  import reuseEcharts from '@/components/reuseEcharts/index'
  import Quarter from '../quarter/index'
  import {
    checkMonthRecord,
    checkDayRecord,
    getCarMonthPage,
    getCarDaysPage,
    searchCarDayElectr,
    searchCarMonthElectr,
    getElectrMonth,
    getElectrDay
  } from '@/utils/vehiclApi'
  import moment from 'moment'
  export default {
    name:'vehicleMsg',
    components:{
      reuseEcharts,
      Quarter
    },
    data() {
      return {
        vehicleData:{},
        classList:{
          yearCount:0,
          dayCount:0,
          monthCount:0
        },
        newObj:{},
        timeState:[],
        activeName:'0',
        yearValue:5,
        dateValue:'',
        timeoptions:[
          {
            value:5,
            label:'年'
          },
          {
            value:4,
            label:'季度'
          },
          {
            value:3,
            label:'月'
          },
          {
            value:2,
            label:'周'
          },
          {
            value:1,
            label:'日'
          }
        ],
        week:'一'
      }
    },
    computed:{
      timeType(){
        switch (this.yearValue) {
          case 5:
            return 'year'
          case 4:
            return 'week'
          case 3:
            return 'month'
          case 2:
            return 'week'
          case 1:
            return 'date'
          default:
            return	''
        }
      },
      timeMat(){
        let time = this.dateValue || new Date()
        let i = moment(time).month()+1
        let wekStr = this.getWeek(moment(time).year(), moment(time).month()+1,moment(time).date())
        switch (this.yearValue) {
          case 5:
            return moment(time).year()+'年'
          case 4:
            return moment(time).year()+'年第'+this.week+'季度'
          case 3:
            return moment(time).year()+'年'+i+'月'
          case 2:
            return wekStr
          case 1:
            return `${moment(time).year()}年${moment(time).month()+1}月${moment(time).date()}日`
          default:
            return	''

        }
      },
      timeholder(){
        switch (this.yearValue) {
          case 5:
            return '请选择年'
          case 4:
            return 'week'
          case 3:
            return '请选择月份'
          case 2:
            return '请选择周'
          case 1:
            return '请选择日期'
          default:
            return	'什么都不选'
        }
      },
      selectTime(){
        let time = this.dateValue ||new Date()
        let startTime = null
        let endTime = null
        switch (this.yearValue) {
          case 5:
            startTime =  moment(time).startOf('year').format('YYYY-MM-DD HH:mm:ss')
            endTime =  moment(time).endOf('year').format('YYYY-MM-DD HH:mm:ss')
            break;
          case 4:
            startTime =  moment(time).startOf('quarter').format('YYYY-MM-DD HH:mm:ss')
            endTime =  moment(time).endOf('quarter').format('YYYY-MM-DD HH:mm:ss')
            break
          case 3:
            startTime =  moment(time).startOf('month').format('YYYY-MM-DD HH:mm:ss')
            endTime =  moment(time).endOf('month').format('YYYY-MM-DD HH:mm:ss')
            break
          case 2:
            startTime =  moment(time).startOf('week').format('YYYY-MM-DD HH:mm:ss')
            endTime =  moment(time).endOf('week').format('YYYY-MM-DD HH:mm:ss')
            break
          case 1:
            startTime =  moment(time).startOf('date').format('YYYY-MM-DD HH:mm:ss')
            endTime =  moment(time).endOf('date').format('YYYY-MM-DD HH:mm:ss')
            break
          default:
            return ''
        }
        return {
          startTime,
          endTime
        }
      },
      dataList(){
        let total = ''
        if(this.activeName === '0'){
          if(this.yearValue === 5 || this.yearValue === 4){
            total = 'monthSwapCount'
          }else if(this.yearValue === 3 || this.yearValue === 2 || this.yearValue === 1){
            total = 'daySwapCount'
          }
        }else{
          if(this.yearValue === 5 || this.yearValue === 4){
            total = 'monthSwapAmount'
          }else if(this.yearValue === 3 || this.yearValue === 2 || this.yearValue === 1){
            total = 'daySwapAmount'
          }
        }
        return {
          total
        }
      },
      arrList(){
        return this.activeName === '0'?['换电次数']:['换电消费']
      }
    },
    created() {
      this.vehicleData = JSON.parse(this.$route.query.data)
      this.getVehicleCount()
      this.getEchartscount()
    },
    methods: {
      //获取换电次数数量
      async getVehicleCount(){
        const res =await getCarDaysPage(this.vehicleData.carNo)
        const result =await getCarMonthPage(this.vehicleData.carNo)
        if(res.status !== 200 && result.status !== 200)return this.$msg.error('获取车辆换电记录失败')
        this.classList.yearCount = result.data.data.yearCount
        this.classList.monthCount = result.data.data.monthCount
        this.classList.dayCount = res.data.data
      },
      //获取换电次数数据
      async getEchartscount(){
        let newList = null
        const data ={
          carNo:this.vehicleData.carNo,
          startTime:this.selectTime.startTime,
          endTime:this.selectTime.endTime
        }
        if(this.yearValue === 5 || this.yearValue === 4){
          const res =await checkMonthRecord(data)
          if(res.status !== 200)return this.$msg.error('获取车辆换电次数失败')
          newList = res.data.data
        }else if(this.yearValue === 3 || this.yearValue === 2 || this.yearValue === 1){
          const res =await checkDayRecord(data)
          if(res.status !== 200)return this.$msg.error('获取车辆换电次数失败')
          newList = res.data.data
        }
        let list = this.sort(newList)
        this.rendeerEcharts(list)
      },
      //获取换电消费金额
      async getVehicleEletr(){
        const res =await getElectrDay(this.vehicleData.carNo)
        const result =await getElectrMonth(this.vehicleData.carNo)
        if(res.status !== 200 && result.status !== 200)return this.$msg.error('获取车辆换电记录失败')
        this.classList.yearCount = result.data.data.yearCount
        this.classList.monthCount = result.data.data.monthCount
        this.classList.dayCount = res.data.data
      },
      //获取换电消费数据
      async getEchartsEletr(){
        let newList = null
        const data ={
          carNo:this.vehicleData.carNo,
          startTime:this.selectTime.startTime,
          endTime:this.selectTime.endTime
        }
        if(this.yearValue === 5 || this.yearValue === 4){
          const res =await searchCarMonthElectr(data)
          if(res.status !== 200)return this.$msg.error('获取车辆换电消费失败')
          newList = res.data.data
        }else if(this.yearValue === 3 || this.yearValue === 2 || this.yearValue === 1){
          const res =await searchCarDayElectr(data)
          if(res.status !== 200)return this.$msg.error('获取车辆换电消费失败')
          newList = res.data.data
        }
        let list = this.sort(newList)
        this.rendeerEcharts(list)
      },
      rendeerEcharts(Newlist){
        let time = this.dateValue || new Date()
        let dayList = null
        let i = null
        let newList = []
        let newArr = []
        switch (this.yearValue) {
          case 5:
            dayList = this.dayLIST(12)
            dayList.forEach(item=>{
              newList.push(this.monthLIST(item))
              i = Newlist.findIndex(c=>moment(c.startTime).month()+1 === item)
              if(i === -1){
                newArr.push(0)
              }else if(i !== -1){
                newArr.push(Newlist.length === 0?0:Newlist[i][this.dataList.total])
              }
            })
            break;
          case 4:
            dayList = this.quarterList(this.week)
            dayList.forEach(item=>{
              newList.push(this.monthLIST(item))
              i = Newlist.findIndex(c=>moment(c.startTime).month()+1 === item)
              if(i === -1){
                newArr.push(0)
              }else if(i !== -1){
                newArr.push(Newlist.length === 0?0:Newlist[i][this.dataList.total])
              }
            })
            break
          case 3:
            let day = moment(time).daysInMonth()
            dayList = this.dayLIST(day)
            dayList.forEach(item=>{
              newList.push(item)
              i = Newlist.findIndex(c=>moment(c.startTime).date() === item)
              if(i === -1){
                newArr.push(0)
              }else if(i !== -1){
                newArr.push(Newlist.length === 0?0:Newlist[i][this.dataList.total])
              }
            })
            break
          case 2:
            dayList = this.dayLIST(7)
            dayList.forEach(item=>{
              newList.push(this.weekList(item))
              i = Newlist.findIndex(c=>{
                  return moment(c.startTime).isoWeekday() === item
                }
              )
              if(i === -1){
                newArr.push(0)
              }else if(i !== -1){
                newArr.push(Newlist.length === 0?0:Newlist[i][this.dataList.total])
              }
            })
            break
          case 1:
            if(Newlist.length !== 0){
              newList.push(Newlist[0].endTime.split(' ')[0])
              newArr.push(Newlist[0][this.dataList.total])
            }else{
              newList.push(moment(this.dateValue).format('YYYY-MM-DD'))
              newArr.push(0)
            }
            break
          default:
            return false
        }
        let list = []
        let item1 = this.arrList
        item1.forEach(item=>{
          if(item ===item1[0]){
            list.push({name:item,type:'bar',data:newArr,itemStyle:{color:'#1b80e1'}})
          }
        })
        this.timeState = newList
        this.newObj ={
          item1,
          list,
          name:this.activeName === '0'?'车辆换电':'车辆消费'
        }
      },
      //获取季度
      quarterClick(e){
        this.dateValue = moment(e.startTime).format('YYYY-MM-DD HH:mm:ss')
        if(e.week === 0){
          this.week = '一'
        }else if(e.week === 1){
          this.week = '二'
        } else if(e.week === 2){
          this.week = '三'
        } else if(e.week === 3){
          this.week = '四'
        }
        if(this.activeName === '0'){
          this.getEchartscount()
        }else{
          this.getEchartsEletr()
        }

      },
      //获取周
      getWeek(a, b, c) {
        /**
         * a = d = 当前日期
         * b = 6 - w = 当前周的还有几天过完(不算今天)
         * a + b 的和在除以7 就是当天是当前月份的第几周
         */
        var date = new Date(a, parseInt(b) - 1, c),
          w = date.getDay(),
          d = date.getDate();
        if(w==0){
          w=7;
        }
        var config={
          getMonth:date.getMonth()+1,
          getYear:date.getFullYear(),
          getWeek:Math.ceil((d + 6 - w) / 7),
        }

        return `${config.getYear}年${config.getMonth}月${config.getWeek}周`
      },
      //排序
      sort(item){
        if(item.length === 0) return []
        let list = item
        list.forEach(item1=>{
          item1.startTime = +moment(item1.startTime).format('x')
        })
        list.sort((a,b)=>a.startTime-b.startTime)
        list.forEach(item1=>{
          item1.startTime = moment(item1.startTime).format('YYYY-MM-DD HH:mm:ss')
        })
        return list
      },
      //转换数组
      dayLIST(list){
        if(list.length === 0) return []
        let newArr = []
        if(this.yearValue !== 1){
          for (let i = 1; i < list+1; i++) {
            newArr.push(i)
          }
        }else{
          for (let i = 0; i < list; i++) {
            newArr.push(i)
          }
        }
        return newArr
      },
      //转换月
      monthLIST(list){
        switch (list) {
          case 1:
            return '一月'
          case 2:
            return '二月'
          case 3:
            return '三月'
          case 4:
            return '四月'
          case 5:
            return '五月'
          case 6:
            return '六月'
          case 7:
            return '七月'
          case 8:
            return '八月'
          case 9:
            return '九月'
          case 10:
            return '十月'
          case 11:
            return '十一月'
          case 12:
            return '十二月'
          default:
            break;
        }
      },
      //转换季度
      quarterList(index){
        if(index === '一'){
          return  [1,2,3]

        }else if(index === '二'){
          return  [4,5,6]
        }else if(index === '三'){
          return  [7,8,9]
        }else if(index === '四'){
          return  [10,11,12]
        }
      },
      //转换周
      weekList(list){
        switch (list) {
          case 1:
            return '周一'
          case 2:
            return '周二'
          case 3:
            return '周三'
          case 4:
            return '周四'
          case 5:
            return '周五'
          case 6:
            return '周六'
          case 7:
            return '周日'
          default:
            break;
        }
      },
      pickerChange(e){
        if(this.activeName === '0'){
          this.getEchartscount()
        }else{
          this.getEchartsEletr()
        }

      },
      changeTabs(e){
        this.yearValue = 5
        this.dateValue = ''
        if(e.name === '0'){
          this.getVehicleCount()
          this.getEchartscount()
        }else{
          this.getVehicleEletr()
          this.getEchartsEletr()
        }
      }
    },
  };
</script>

<style lang='less' scoped>
  li {
    list-style: none;
  }
  .el-select,.el-date-editor.el-input, .el-date-editor.el-input__inner,.el-form{
    width: 100%;
  }
  .el-avatar{
    margin-bottom: 27px;
  }
  .vehicle-title{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 30px;
    padding-left: 20px;
    font-weight: 700;
    border-left: 6px solid #2a82e4;
    >i{
      color: #2a82e4;
      cursor: pointer;
      margin-left: 20px;
    }
  }
  .timeSelect{
    display: flex;
    align-items: center;
    margin-right: 30Px;
    div{
      margin-right: 20px;
    }
    .time1{
      width: 100px;
    }
    .time2{
      width: 250px;
    }

  }
  .electric{
    height: 200px;
    .el-col{
      height: 80px;
      border-radius: 10px;
      background-color: #2a82e4;
      margin: 20px;
      display: flex;
      color: #fff;

      &:nth-child(2){
        background-color: #ee9e4d;
        margin:20px  40px;

      }
      &:nth-child(3){
        background-color: #00baad;
      }
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-chongdianzhong{
          font-size: 40px;
          color: #a9a9a9;
        }
        .icon-xiaofei1{
          font-size: 40px;
          color: #a9a9a9;
        }
        &:nth-child(1){
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &:nth-child(2){
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          height: 100%;

          >div{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
          }
        }
      }
    }
  }

  .vehicleMsg{
    border-bottom: 1px solid #ccc;
    .basics-ul {
      display: flex;
      flex-direction: column;
      width: 100%;
      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 50px;
        div {
          width: 20%;
          height: 100%;
          display: flex;
          align-items: center;

          span {
            font-size: 16px;
            &:nth-child(2) {
              color:#0e0e0e;
            }
          }
        }
      }
      .auditState {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-bottom: 2px;
        margin-right: 3px;
      }
    }
  }

</style>
