<template>
  <div id="branch-pie"></div>
</template>
 
<script>
import * as echarts from 'echarts'
export default {
     props:{
      newPie:{
          type:Object,
          required:true,
          value:()=>{}
      }
    },
       name:'',
       data(){ 
       return{
        myChart:null
       }
    },
     watch:{
       newPie:{
           handler(newValue,oldValue){
              if(this.myChart){
                if(newValue){
                    var option = this.myChart.getOption()
                    option.series[0].data = newValue.list
                    option.title[0].text = newValue.name
                    this.myChart.setOption(option)
                }else{
                    var option = this.myChart.getOption()
                    option.series[0].data = oldValue.list
                    option.title[0].text = oldValue.name
                    this.myChart.setOption(option)
                }
              }else{
                this.renderInit()
              }
           },
           deep:true 
       }
   }, 
  mounted() {
      this.renderInit()
  },
  methods: {
      renderInit(){
              this.myChart = echarts.getInstanceByDom(document.getElementById('branch-pie'))
            if(!this.myChart){
                this.myChart= echarts.init(document.getElementById('branch-pie'));
            }
           var  option = {
               title:{
                          text:this.newPie.name,
                          top:'3%',
                          left:'2%',
                          textStyle:{
                              color:'#5d5d5d'
                          }
                        },
                legend: {
                            right: '5%',
                            bottom:'2%',
                            orient:'vertical'
                        },
                series: [
                    {
                        name: '电池分布数据',
                        type: 'pie',
                        radius: ['30%', '50%'],
                        center:['40%','50%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            show: true,
                            position: 'center',
                            fontSize: '12',
                                fontWeight: 'bold',
                                formatter:'{b}\n{c}%'
                        },
                        labelLine: {
                            show: false
                        },
                        data:this.newPie.list
                    }
                ]
            };
            
        this.myChart.setOption(option)
        let chart = this.myChart
         setTimeout( ()=>{
              window.addEventListener("resize", () => { chart.resize(); })
            },200)
      }
  },
}
</script>
 
<style lang='less' scoped>
 #branch-pie{
     width: 100%;
     height: 400Px;
 }
</style>