<template>
  <div style="width:100%;height:100%;">
       <div id="consumed" ></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
    name:'EchartsName',
    props:{
       optionsList:{
           type:Object,
           required:true,
           value:()=>{}
       },
       timeList:{
           type:Array,
           required:false,
           value:()=>[]
       }

     },
       data(){
       return{
        myChart:null
       }
    },
   created(){

   },
   mounted() {
           this.renderInit()
   },
  watch:{
     timeList:{
         handler:function(newValue,oldValue){
           if(this.myChart){
                if(newValue){
                var option = this.myChart.getOption()
                    option.xAxis[0].data = newValue
                    this.myChart.setOption(option)
                }else{
                    var option = this.myChart.getOption()
                        option.xAxis[0].data = oldValue
                        this.myChart.setOption(option)
                }
           }else{
                this.renderInit()
           }
      },
      deep:true
     },
     optionsList:{
         handler:function(newValue,oldValue){
        if(this.myChart){
             if(newValue){
           var option = this.myChart.getOption()
            option.series = newValue.list
            option.title[0].text = newValue.name
            option.legend[0].data = newValue.item
            this.myChart.setOption(option)
            }else{
                var option = this.myChart.getOption()
                option.series = oldValue.list
                 option.title[0].text = oldValue.name
                option.legend[0].data = oldValue.item
                    this.myChart.setOption(option)
            }
        }else{
             this.renderInit()
        }
      },
      deep:true
     }

  },
   methods: {
        renderInit(){
            this.myChart = echarts.getInstanceByDom(document.getElementById('consumed'))
            if(!this.myChart){
                this.myChart = echarts.init(document.getElementById('consumed'));
            }

         var  option = {
           tooltip: {
             trigger: 'axis'
           },
            color:'#3c90f7',
            title:{
                text:this.optionsList.name,
                textStyle:{
                    fontWeight:'400',
                    fontSize:12
                },
                top:'-1%',
                left:'2%'
            },
            legend:{
              data:this.optionsList.item
            },
            grid:{
                left:'5%',
                top:'10%',
                right:'1%',
                bottom:'10%'
            },
            xAxis: {
                type: 'category',
                data:this.timeList,
                axisTick:{
                    show:false
                },
                  axisLine:{
                      lineStyle:{
                          color:'#e4e4e4'
                      }
                  },
                  axisLabel:{
                      color:'#333'
                  }
            },
            yAxis: {
                type: 'value',
                show:true,
                axisTick:{
                    show:false
                }
            },
            series:this.optionsList.list
        };

        this.myChart.setOption(option)
        let chart = this.myChart
         setTimeout( ()=>{
              window.addEventListener("resize", () => { chart.resize(); })
            },200)


     }

   },
}
</script>

<style lang='less' scoped>
 #consumed{
      width: 100%;
    height: 400px;
   }
</style>
