<template>
  <div id="vehicle-pie"></div>
</template>

<script>
  import * as echarts from 'echarts'

  export default {
    props: {
      newPie: {
        type: Array,
        required: true,
        value: () => []
      },
      namePie: {
        type: String,
        required: true,
        value: () => ''
      }
    },
    name: 'vehiclePie',
    data() {
      return {
        myChart: null
      }
    },
    watch: {
      newPie: {
        handler(newValue, oldValue) {
          if (this.myChart) {
            if (newValue) {
              var option = this.myChart.getOption()
              option.series[0].data = newValue
              this.myChart.setOption(option)
            } else {
              var option = this.myChart.getOption()
              option.series[0].data = oldValue
              this.myChart.setOption(option)
            }
          } else {
            this.renderInit()
          }
        },
        deep: true
      },
      namePie: {
        handler(newValue, oldValue) {
          if (this.myChart) {
            if (newValue) {
              var option = this.myChart.getOption()
              option.title[0].text = newValue
              this.myChart.setOption(option)
            } else {
              var option = this.myChart.getOption()
              option.title[0].text = oldValue
              this.myChart.setOption(option)
            }
          } else {
            this.renderInit()
          }
        },
        deep: true
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.renderInit()
      })
    },
    methods: {
      renderInit() {
        this.myChart = echarts.getInstanceByDom(document.getElementById('vehicle-pie'))
        if (!this.myChart) {
          this.myChart = echarts.init(document.getElementById('vehicle-pie'));
        }
        let name = this.namePie.slice(2, 4)
        var option = {
          title: {
            text: this.namePie,
            top: '3%',
            left: '2%',
            textStyle: {
              color: '#5d5d5d'
            }
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            right: '5%',
            bottom: '2%',
            orient: 'vertical'
          },
          series: [
            {
              name: '车辆换电统计',
              type: 'pie',
              radius: ['35%', '60%'],
              center: ['40%', '50%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '18',
                  fontWeight: 'normal',
                  formatter: (params) => {
                    // return params.value + '元'
                    return params.value + (name === '换电'?'次':'元')
                  }
                }
              },
              labelLine: {
                show: false
              },
              data: this.newPie
            }
          ]
        };

        this.myChart.setOption(option)
        let chart = this.myChart
        setTimeout(() => {
          window.addEventListener("resize", () => {
            chart.resize();
          })
        }, 200)
      }
    },
  }
</script>

<style lang='less' scoped>
  #vehicle-pie {
    width: 100%;
    height: 400Px;
  }
</style>
