<template>
  <div id="elec-energy-pie"></div>
</template>

<script>
  import * as echarts from 'echarts'

  export default {
    props: {
      newPie: {
        type: Object,
        required: true,
        value: () => {
        }
      },
    },
    name: 'vehiclePie',
    data() {
      return {
        myChart: null
      }
    },
    watch: {
      newPie: {
        handler(newValue, oldValue) {
          if (this.myChart) {
            if (newValue) {
              var option = this.myChart.getOption()
              option.series[0].data = newValue.list
              this.myChart.setOption(option)
            } else {
              var option = this.myChart.getOption()
              option.series[0].data = oldValue.list
              this.myChart.setOption(option)
            }
          } else {
            this.renderInit()
          }
        },
        deep: true
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.renderInit()
      })
    },
    methods: {
      renderInit() {
        let list = this.newPie
        if (this.myChart != null && this.myChart != "" && this.myChart != undefined) {
          this.myChart.dispose();//销毁
        }
        this.myChart = echarts.init(document.getElementById('elec-energy-pie'));
        var option = {
          title: {
            text: this.newPie.name,
            top: '1%',
            left: '1%',
            textStyle: {
              color: '#5d5d5d'
            }
          },
          tooltip: {
            trigger: 'item'
          },
            legend: {
                right: '5%',
                top: 'center',
                orient: 'vertical',
                itemGap: 16,
                textStyle: {
                    fontSize:14,
                    margin:[0,0,16,0]
                }
            },
            color:['#0D6CE4','#FA9600','#19B21E'],
          series: [
            {
              // name: '场站电量结果环比',
              type: 'pie',
                radius: ['35%', '55%'],
                center: ['35%', '50%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '18',
                  fontWeight: 'normal',
                  formatter: '{d}%',
                  // formatter:(params)=>{
                  //     return params.value+'次'
                  // }
                }
              },
              labelLine: {
                show: false
              },
              data:list.list
            }
          ]
        };

        this.myChart.setOption(option)
        let chart = this.myChart
        setTimeout(() => {
          window.addEventListener("resize", () => {
            chart.resize();
          })
        }, 200)
      }
    },
  }
</script>

<style lang='less' scoped>
  #elec-energy-pie {
    width: 100%;
    height: 319px;
  }
</style>
