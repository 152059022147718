<template>
    <div id="app">
        <router-view/>

        <global-uploader></global-uploader>

    </div>
</template>

<script>
import globalUploader from '@/views/background/fileuploader/globalUploader'
export default {
    name: 'App',
    components: {
        globalUploader
    },
    data() {
        return {}
    },

    watch: {},

    created() {

    },


    mounted: function () {
    },

    methods: {}

}
</script>

<style lang="less">
.icon-list div {
    min-height: 45px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    width: 100%;
    height: 100%;
    min-width: 1400PX;
}

body {
    overflow: auto;
    font-family: 'Microsoft YaHei', "MicrosoftJhengHei";
}

#app {
    height: 100%;
    margin: 0 auto;
}

.el-select-dropdown .el-scrollbar .el-scrollbar__wrap {
    overflow: scroll !important;
}

.el-cascader-menu {
    height: 300px;
}

.el-cascader-panel .el-radio {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    top: 10px;
    right: 10px;
}

.el-cascader-panel .el-radio__input {
    visibility: hidden;
}

/* 这个样式针对IE有用，不考虑IE的可以不用管*/
.el-cascader-panel .el-cascader-node__postfix {
    top: 10px;
}
</style>


<style>
/*设置树形最外层的背景颜色*/
.el-tree {
    width: 100%;
    height: 800px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    background: transparent;
}

.el-tree-node {
    padding-top: 5px;
    margin-top: 10px;

}


.el-tree-node .el-tree-node__children {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 3px;
}

/*设置三角图标的颜色*/
/*
.el-tree-node__expand-icon{
  color:#62b2ff;
}
*/

/*设置节点鼠标悬浮三角图标 鼠标悬浮的颜色*/

/*.el-tree-node__content:hover .el-tree-node__expand-icon{*/
/*  color:red*/
/*}*/

/*.el-tree-node__content:hover .el-tree-node__expand-icon.is-leaf{*/
/*  color:#000;*/
/*  background-color: #62b2ff;*/
/*}*/

/*树节点鼠标悬浮时改变背景颜色和字体颜色*/
/*.el-tree-node__content:hover>.el-tree-node__content{
  background-color: #62b2ff;
  color:#000;
}*/

/*节点前边的三角图标并不会被节点样式影响，需单独修改*/
/*
.el-tree-node:focus>.el-tree-node__content .el-tree-node__expand-icon{
  color:#000;
}
*/

/*改变被单击节点背景颜色，背景颜色改为白色，字体为黑色*/
/*
.el-tree-node:focus>.el-tree-node__content{
  background-color: #d0ecff;
  color: #000;
}
*/

/*
.el-tree-node.is-current>.el-tree-node__content {
  background-color: #d0ecff;
}
*/

/*
.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #d0ecff;
}
*/

/*改变高度*/
.el-tree-node__content {
    line-height: 60px;
    font-size: 15px;
    padding-left: 3px;
    margin-top: 5px;
}


/*tree组件选中背景色修改 */
.el-tree-node.is-current > .el-tree-node__content {
    background-color: #ffffff;
    color: #62b2ff;;
    height: 60px;
    font-size: 20px;
    /* border:  #62b2ff solid 1px;*/
    border-left: 3px solid #02a6b5;

}

/*tree组件鼠标hover悬浮背景色 */
.el-tree-node > .el-tree-node__content:hover {
    background-color: #f3f3f3;
    color: #62b2ff;
}

.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    padding-right: 20px;
}


.el-table__row {
    font-family: 'Microsoft YaHei', "MicrosoftJhengHei";
    -webkit-box-direction: normal;
    font-size: 0.08rem;
    color: #606266;
    line-height: 0.19rem;
    border-collapse: separate;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #FFF;
    height: 60px;
}

.app-container {
    padding-left: 20px;
    margin-top: 20px;
    margin-left: 20px;
    font-size: 18px;
    background-color: #ffffff;
}

.out-container {
    width: 100%;
    /* height: var(--tableHeight);*/
    height: 610px;
    overflow: auto;
}


/*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
.out-container::-webkit-scrollbar {
    width: 10px; /*对垂直流动条有效*/
    height: 10px; /*对水平流动条有效*/
}

/*定义滚动条的轨道颜色、内阴影及圆角*/
.out-container::-webkit-scrollbar-track {
    border-radius: 4px;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
    /* background-color: rosybrown; */
}

/*定义滑块颜色、内阴影及圆角*/
.out-container::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #DDDEE0;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
}

/*定义滑块悬停变化颜色、内阴影及圆角*/
.out-container::-webkit-scrollbar-thumb:hover {
    background-color: #62b2ff;
}

/*定义两端按钮的样式*/
.out-container::-webkit-scrollbar-button {
    background-color: #d3dce6;
}

/*定义右下角汇合处的样式*/
.out-container::-webkit-scrollbar-corner {
    background: khaki;
}

/*按钮大小*/
.buttonsize{
    width: 88px;
    height: 40px;
    padding: 0 !important;
    font-size: 14px !important;
}


/**
.table-style {
  height: 620px;
  overflow-x: scroll;
  overflow-y: scroll;
}
**/


/*
overflow: scroll !important;
overflow-y: scroll;
overflow-x: scroll;

**/

</style>


