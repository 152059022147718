<template>
  <el-form>
    <el-form-item>
      <mark
        class="_mark"
        v-show="showSeason"
        @click.stop="showSeason=false"
      ></mark>
      <el-input placeholder="请选择季度" v-model="showValue"  @focus="showSeason=true">
        <i slot="prefix" class="el-input__icon el-icon-date"></i>
      </el-input>
      <el-card
        class="box-card"
        v-show="showSeason"
      >
        <div slot="header" class="clearfix yearBtn">
          <button
            type="button"
            aria-label="前一年"
            class="el-picker-panel__icon-btn el-date-picker__prev-btn el-icon-d-arrow-left"
            @click="prev"
          ></button>
          <span role="button" class="el-date-picker__header-label">{{year}}年</span>
          <button
            type="button"
            aria-label="后一年"
            @click="next"
            class="el-picker-panel__icon-btn el-date-picker__next-btn el-icon-d-arrow-right"
          ></button>
        </div>
        <div class="text item">
          <el-button
            type="text"
            size="medium"
            class="_left"
            @click="selectSeason(0)"
          >第一季度</el-button>
          <el-button
            type="text"
            size="medium"
            class="_right"
            @click="selectSeason(1)"
          >第二季度</el-button>
        </div>
        <div class="text item">
          <el-button
            type="text"
            size="medium"
            class="_left"
            @click="selectSeason(2)"
          >第三季度</el-button>
          <el-button
            type="text"
            size="medium"
            class="_right"
            @click="selectSeason(3)"
          >第四季度</el-button>
        </div>
      </el-card>
    </el-form-item>
  </el-form>
</template>
<script>
import moment from 'moment'
export default {
    name:'quarterName',
  data() {
    return {
      valueArr:['01-03', '04-06', '07-09', '10-12'],
      showSeason: false,
      season: '',
      year: moment().year(),
      showValue: '',
      index:0
    }
  },
  created() {

  },
  methods: {
    one() {
      this.showSeason = false
    },
    prev() {
        this.index = this.index - 1
        this.year = moment().add(this.index, 'y').format("YYYY")
    },
    next() {
      this.index = this.index + 1 
      this.year = moment().add(this.index, 'y').format("YYYY")
    },
    selectSeason(i,time) {
       let changeTime = time || this.year
       this.showSeason = false
       let timeValue = null
       if(i === 0){
           timeValue = `${changeTime}-${this.valueArr[0].split('-')[0]}`
           this.showValue = `${changeTime}年第一季度` 
       }else if(i === 1){
           timeValue = `${changeTime}-${this.valueArr[1].split('-')[0]}` 
           this.showValue = `${changeTime}年第二季度` 
       }else if(i === 2){
           timeValue = `${changeTime}-${this.valueArr[2].split('-')[0]}`
           this.showValue = `${changeTime}年第三季度`  
       }else if(i === 3){
           timeValue = `${changeTime}-${this.valueArr[3].split('-')[0]}` 
           this.showValue = `${changeTime}年第四季度` 
       }
       const startTime = moment(moment().format(timeValue)).startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
       const endTime = moment(moment().format(timeValue)).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
       this.$emit('quarter',{startTime,endTime,week:i})
    }
  }
}
</script>
<style lang="less" scoped>
._mark {
  position:fixed;top:0;bottom:0;left:0;right:0;background:rgba(0,0,0,0);z-index:999;
}
.yearBtn {
  text-align:center;padding:0
}
.box-card {
  width:300px;padding: 0 3px 20px;margin-top:10px;position:absolute;z-index:9999
}
/deep/.el-input{
    width: 100%;
}
.el-form-item{
    margin-bottom: 0;
}
.text.item {
  text-align: center;
}
/deep/.text.item  .el-button{
  width:40%;color: #606266;
}
.text.item ._left {
  float: left;
}
.text.item ._right {
  float: right;
}
</style>