export function provincepy() {
    var datas = [
        //23个省
        {
            name: "台湾省", pinyin: "TW"
        },
        {
            name: "河北省", pinyin: "HB"
        },
        {
            name: "山西省", pinyin: "SX"
        },
        {
            name: "辽宁省", pinyin: "LN"
        },
        {
            name: "吉林省", pinyin: "JL"
        },
        {
            name: "黑龙江省", pinyin: "HL"
        },
        {
            name: "江苏省", pinyin: "JS"
        },
        {
            name: "浙江省", pinyin: "ZJ"
        },
        {
            name: "安徽省", pinyin: "AH"
        },
        {
            name: "福建省", pinyin: "FJ"
        },
        {
            name: "江西省", pinyin: "JX"
        },
        {
            name: "山东省", pinyin: "SD"
        },
        {
            name: "河南省", pinyin: "HA"
        },
        {
            name: "湖北省", pinyin: "HB"
        },
        {
            name: "湖南省", pinyin: "HN"
        },
        {
            name: "广东省", pinyin: "GD"
        },
        {
            name: "海南省", pinyin: "HI"
        },
        {
            name: "四川省", pinyin: "SC"
        },
        {
            name: "贵州省", pinyin: "GZ"
        },
        {
            name: "云南省", pinyin: "YN"
        },
        {
            name: "陕西省", pinyin: "SN"
        },
        {
            name: "甘肃省", pinyin: "GS"
        },
        {
            name: "青海省", pinyin: "QH"
        },
        //5个自治区
        {
            name: "新疆维吾尔自治区", pinyin: "XJ"
        },
        {
            name: "广西壮族自治区", pinyin: "GX"
        },
        {
            name: "内蒙古自治区", pinyin: "NM"
        },
        {
            name: "宁夏回族自治区", pinyin: "NX"
        },
        {
            name: "西藏自治区", pinyin: "XZ"
        },
        //4个直辖市
        {
            name: "北京市", pinyin: "BJ"
        },
        {
            name: "天津市", pinyin: "TJ"
        },
        {
            name: "上海市", pinyin: "SH"
        },
        {
            name: "重庆市", pinyin: "CQ"
        },
        //2个特别行政区
        {
            name: "香港特别行政区", pinyin: "HK"
        },
        {
            name: "澳门特别行政区", pinyin: "MO"
        }];
    return datas;
}

