<template>
  <div class="user_avater">
    <el-upload
      class="avatar-uploader"
      action="#"
      list-type="picture-card"
      :headers="headers"
      :show-file-list="false"
      :http-request="uploadRequest"
      :before-upload="beforeAvatarUpload">
      <img v-if="imgAvatar" :src="imgAvatar" class="avatar">
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
  import {imgUpload} from "@/utils/user";
  import {triggerRef} from "vue";

  export default {
    props: {
      uploadImg: {
        type: String,
        required: false,
        default: () => ''
      },
      isAvater: {
        type: Boolean,
        required: false,
        default: () => true
      }
    },
    name: 'avater',
    data() {
      return {
        headers: {
          Authorization: null
        },
        imgAvatar: '',
        fileAvter: ''
      }
    },
    watch: {
      uploadImg: {
        handler(newValue, oldValue) {
            console.log(newValue,'----',oldValue)
                this.imgAvatar = newValue
        },
      },
      isAvater: {
        handler(newValue, oldValue) {
            console.log(newValue,'isAvater')
          if (!newValue) {
            this.imgAvatar = ''
            this.$emit('on-imgchange', true)
          }
        }
      }
    },
    created() {
      this.headers.Authorization = this.token
      this.imgAvatar = this.uploadImg
    },
    computed: {
      token() {
        return 'Bearer ' + this.$store.state.token.access_token
      }
    },
    methods: {
      beforeAvatarUpload(file) {
        let isType = true
        let isSize = true
        if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif') {
          isType = false
          return this.$msg.warning('不是有效的图片文件')
        }
        if ((file.size / 1024 / 1024 / 2) > 2) {
          isSize = false
          return this.$msg.warning('图片不能大于2M')
        }
        this.fileAvter = file
        this.imgAvatar = window.URL.createObjectURL(file)

        return isType && isSize
      },
      async uploadRequest() {
        try {
          var fromData = new FormData()
          fromData.append('file', this.fileAvter)
          const res = await imgUpload(fromData)
          if (res.status !== 200) return this.$msg.error('图片上传失败')
          this.$emit('on-avater', res.data.data)
        } catch (error) {

        }
      }
    }
  }
</script>

<style lang='less' scoped>
  .user_avater {
    width: 100%;
    height: 100%;

    /deep/ .avatar-uploader {
      .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .el-upload:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        color: #8c939d;
        width: 100%;
        height: 100%;
        align-items: center;
      }
    }
  }

  .avatar {
    display: flex;
    justify-content: space-around;
    border: 1px rgb(245, 245, 245) ridge;
    width: 100%;
    height: 100%;


    //width: 178px;
    //height: 178px;
    //display: block;
  }
</style>
